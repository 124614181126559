import { Injectable } from '@angular/core';
import { WasabiService } from './wasabi.service';
import { parseISO, addSeconds } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class IndexDbService {

  constructor(private wasabiService: WasabiService) {
  }

  getDbInstance = () => {
    return new Promise((resolve) => {
      const request = indexedDB.open('BS', 1);

      request.onupgradeneeded = (event) => {
        // @ts-ignore
        const dbObj = event.target.result;
        dbObj.createObjectStore('images', { keyPath: 'path' });
      };

      request.onsuccess = async () => {
        resolve(request.result);
      };
    });
  }

  getPresignedImageUrl = async (path) => {
    if (path !== null && path !== undefined) {
      let presignedUrl: any = '';

      const db = await this.getDbInstance();
      //console.log('getPresignedImageUrl init db')
      presignedUrl = await this.getByPath(db, path);
      //console.log('getPresignedImageUrl init urlby path')
      if (presignedUrl) {
        //console.log("db getPresignedImageUrl url",presignedUrl);
        // @ts-ignore
        db.close();
        return presignedUrl;
      } else {
        const url = this.wasabiService.getWasabiPresignedUrl(path);
        //console.log("new getPresignedImageUrl url",url);
        presignedUrl = await this.savePresignedUrl(db, path, url);
        if (presignedUrl) {
          // @ts-ignore
          db.close();
          return presignedUrl;
        }
      }
    }
  }

  getByPath = (db, path) => {
    return new Promise(async (resolve) => {
      const request = db.transaction('images').objectStore('images').get(path);
      request.onsuccess = async () => {
        if (request.result) {

          //const currentTs = Math.floor(Date.now() / 1000);
          const currentTs = new Date();
          /*console.log("getByPath currentTs :",currentTs);
          console.log("getByPath exp_time :",request.result.exp_time);*/
          if (currentTs > request.result.exp_time) {
            console.log("getByPath expired generate new");
            await this.deleteByPath(db, path);
            const newUrl = this.wasabiService.getWasabiPresignedUrl(path);
            const newPresignedUrl = await this.savePresignedUrl(db, path, newUrl);
            resolve(newPresignedUrl);
          } else {
            resolve(request.result.presignedUrl);
          }
        } else {
          resolve(null);
        }
      };
      request.onerror = (error) => {
        //console.log("request.result error : ",error)
        resolve(null);
      };
    });
  }

  deleteByPath = (db, path) => {
    return new Promise((resolve) => {
      const request = db
        .transaction('images', 'readwrite')
        .objectStore('images')
        .delete(path);

      request.onsuccess = async () => {
        resolve(true);
      };
      request.onerror = () => {
        resolve(null);
      };
    });
  }

  savePresignedUrl = (db, path, presignedUrl) => {
    return new Promise((resolve) => {
      const urlParams = new URLSearchParams(presignedUrl);
      const creationDate = parseISO(urlParams.get('X-Amz-Date'));
      const expiresInSecs = Number(urlParams.get('X-Amz-Expires'));
      const expiryDate = addSeconds(creationDate, expiresInSecs);

      const request = db
        .transaction('images', 'readwrite')
        .objectStore('images')
        .add({
          path,
          presignedUrl,
          exp_time: expiryDate,
        });

      request.onsuccess = async () => {
        if (request.result) {
          resolve(presignedUrl);
        } else {
          resolve(null);
        }
      };
      request.onerror = (error) => {
        resolve(null);
      };
    });
  }
}
