import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  updateProfile(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('update_profile'), postValue);
  }

  changePassword(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('change_password'), postValue);
  }

  subscribe(id, postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('subscribe'), postValue);
  }

  upgradeSubscribePlan(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('upgrade_subscribe_plan'), postValue);
  }

  addCard(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_card'), postValue);
  }

  makeCardDefault(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('make_card_default'), postValue);
  }

  deleteCard(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_card'), postValue);
  }

  getUserSaveCard(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_user_save_card'));
  }

  getSocialAccountList(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_social_accounts_list'));
  }

  deleteAccount(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_account'), postValue);
  }

  cancelSubscription(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('cancel_subscription'), postValue);
  }

  cancelDowngradeSubscription(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('cancel_downgrade_subscription'));
  }

  paymentIntent(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('payment_intent'));
  }

  getTopNotification(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_top_notification'));
  }

  setNotificationCount(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('set_notification_count'));
  }

  getAllNotification(page,timeZone): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_all_notification') + '?page=' + page + '&timezone='+timeZone);
  }

  saveNotificationSetting(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('notification_setting'), postValue);
  }

  getNotificationSetting(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_notification_setting'));
  }

  getPlanDetail(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('manage_membership'));
  }

  /*Notifications*/
  markAsReadNotification(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('mark_as_read_notification'), postValue);
  }
  deleteNotification(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_notifications'), postValue);
  }

  applyCoupon(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('apply_coupon'), postValue);
  }

  saveContactRequest(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('contact'), postValue);
  }

  endTour(): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('end_tour'), []);
  }

  /*Addon*/
  getAddonDetail(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_addon'));
  }

  buyAddon(postValue): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('buy_addon'),postValue);
  }

  /* Transaction History */
  getTransactionHistory(page,limit,search='',status='',type='',startDate,endDate): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_transaction_history')+'?page=' + page +'&limit='+limit + '&search=' + search + '&status='+status + '&type='+ type + '&startDate='+startDate +'&endDate=' + endDate);
  }


}
